<template>
  <div
      v-if="getValidation"
      class="content-wrapper"
  >
    <page-header
        screen-name="Editar Usuário Departamento Api"
        :link-items="linkItems"
    />

    <div
        v-if="loading"
        class="spinner-area"
    >
      <b-spinner
          variant="custom"
          label="Loading..."
      />
    </div>

    <Form
        v-if="!loading"
        mode="update"
        :form-data="form"
        @clear="clearForm"
    />
  </div>
</template>

<script>

import { BSpinner } from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader';
import { warningMessage } from '@/libs/sweetalerts'
import Form from './Form.vue'
import {getUserDepApi} from "@/views/custom-pages/Suporte/UsuariosIntegracao/requests";

export default {
  components: {
    BSpinner,
    PageHeader,
    Form,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Usuários Departamento Api',
          routeName: '',
        },
        {
          name: 'Editar Usuário Departamento Api',
          active: true,
        },
      ],

      loading: false,

      validation: false,

      itemIdStore: this.$store.state.departamentoApiState.departmentApiUser,

      form: {
        endpoints: []
      },
    }
  },

  computed: {
    getValidation() {
      return this.validation
    },
  },

  watch: {
    itemIdStore(value) {
      return value
    },
  },

  created() {
    if (!this.itemIdStore) {
      this.redirectToMainPage()
    }

    this.validation = true
  },

  mounted() {
    this.getChooseItem()
  },

  methods: {
    async getChooseItem() {
      this.loading = true

      await getUserDepApi(this.itemIdStore)
          .then(response => {
            const res = response.data

            this.form.id = res.id_departamento_api
            this.form.endpoints = res.endpoint
          })
          .catch(() => {
            warningMessage('Não foi possível realizar a sua solicitação.')
          })

      this.loading = false
    },

    redirectToMainPage() {
      this.clearForm()
      this.$store.commit('departamentoApiState/SET_CHOOSE_DEPARTMENT_API_USER', null)
      this.$router.replace({ name: 'home' })

      return false
    },

    clearForm() {
      this.form = {
        endpoints: []
      }
    },
  },
}
</script>
