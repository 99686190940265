<template>
  <div class="card">
    <section class="p-card-form">
      <div v-if="loading" class="spinner-area">
        <b-spinner variant="custom" label="Loading..." />
      </div>

      <validation-observer v-if="!loading" ref="formItems">
        <b-form>
          <b-row>
            <b-col v-if="this.getMode === 'insert'" sm="6" lg="4">
              <b-form-group
                label="Tipo de Departamento"
                label-for="departmentType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo Departamento"
                  rules="required"
                >
                  <v-select
                    id="departmentType"
                    v-model="getFormData.departmentType"
                    :options="departmentTypeOptions"
                    variant="custom"
                    item-text="description"
                    item-value="id"
                    label="description"
                    placeholder="Escolha um tipo"
                    @input="checkNationalDepartmentType"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="this.getMode === 'insert'" sm="6" lg="4">
              <b-form-group label="Departamento" label-for="department">
                <validation-provider
                  #default="{ errors }"
                  name="Departamento"
                  rules="required"
                >
                  <v-select
                    id="status"
                    v-model="getFormData.department"
                    :options="departmentsOptions"
                    variant="custom"
                    item-text="descricao"
                    item-value="id_departamento"
                    placeholder="Escolha um departamento"
                    label="descricao"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="6" lg="4">
              <b-form-group label="Acessos" label-for="endpoints">
                <div class="d-flex flex-column align-items-end">
                  <v-select
                    id="categories"
                    v-model="getFormData.endpoints"
                    :options="endpoints"
                    variant="custom"
                    class="w-100 mb-1"
                    item-text="descricao"
                    item-value="id_endpoint"
                    placeholder="Selecione um ou mais"
                    label="descricao"
                    multiple
                    multiselect
                  />

                  <b-link
                    @click="handleSelectAllEndpoints"
                    class="select-success"
                  >
                    Selecionar todos
                  </b-link>

                  <b-link
                    v-if="getFormData.endpoints.length > 0"
                    @click="handleClearAllEndpointsSelect"
                    class="select-danger"
                  >
                    Limpar tudo
                  </b-link>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-3">
              <button
                type="button"
                class="btn custom-blue mr-2"
                @click="formSubmit(true)"
              >
                <feather-icon icon="CheckIcon" />
                Salvar Usuário
              </button>

              <button
                v-if="getMode === 'insert'"
                type="button"
                class="btn custom-outline-blue mr-2"
                @click="formSubmit(false)"
              >
                <feather-icon icon="CheckIcon" />
                Salvar e cadastrar novo
              </button>

              <button
                type="button"
                class="btn btn-outline-danger button-custom-size"
                @click="cancel"
              >
                <feather-icon icon="XIcon" />
                Cancelar
              </button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
  BLink,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import {
  createDepartmentApiUser,
  getAllDepartments,
  getAllEndpoints,
  updateDepartmentApiUser,
  getAllTypeDepartments,
} from "@/views/custom-pages/Suporte/UsuariosIntegracao/requests";
import { successMessage, warningMessage } from "@/libs/sweetalerts";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BLink,
  },

  props: {
    mode: {
      type: String,
      default: "insert",
    },

    formData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      required,
      titlePage: "",

      loading: true,

      departmentTypeOptions: [],
      departmentsOptions: [],

      departments: [],
      endpoints: [],
    };
  },

  computed: {
    getMode() {
      return this.mode;
    },

    getFormData() {
      return this.formData;
    },
  },

  mounted() {
    this.findTypeDepartament();
    this.findAllDepartments();
    this.findAllEndpoints();
  },

  methods: {
    async findTypeDepartament() {
      this.loading = true;

      await getAllTypeDepartments()
        .then((response) => {
          if (response.status === 200) {
            this.departmentTypeOptions = response.data.map((type) => {
              return {
                id: type.id_tipo_departamento,
                boolValue: type.id_tipo_departamento == 1,
                description: type.descricao,
              };
            });
          }
        })
        .catch(() => {});

      this.loading = false;
    },

    async findAllDepartments() {
      this.loading = true;

      await getAllDepartments()
        .then((response) => {
          if (response.status === 200) {
            const departments = response.data;

            this.departments = departments.reduce((acc, departamento) => {
              const tipoDepartamento = departamento.id_tipo_departamento;

              if (!acc[tipoDepartamento]) {
                acc[tipoDepartamento] = [];
              }

              acc[tipoDepartamento].push(departamento);
              return acc;
            }, {});
          }
        })
        .catch(() => {});

      this.loading = false;
    },

    async findAllEndpoints() {
      this.loading = true;

      await getAllEndpoints()
        .then((response) => {
          this.endpoints = response.data;
        })
        .catch(() => {});

      this.loading = false;
    },

    handleSelectAllEndpoints() {
      this.getFormData.endpoints = this.endpoints;
    },

    handleClearAllEndpointsSelect() {
      this.getFormData.endpoints = [];
    },

    async formSubmit(redirect) {
      this.redirect = redirect;

      const result = new Promise((resolve, reject) => {
        this.$refs.formItems
          .validate()
          .then((success) => {
            if (success) {
              resolve(true);
            }
          })
          .catch(() => {
            reject();
          });
      });

      if (await result) {
        await this.core();
      }
    },

    async core() {
      if (this.getMode === "insert") {
        await this.handleCreate();
      }

      if (this.getMode === "update") {
        await this.handleUpdate();
      }
    },

    async handleCreate() {
      this.loading = true;

      const formData = this.setFormData();

      await createDepartmentApiUser(formData)
        .then((response) => {
          if (response.status === 200) {
            this.clear();
            successMessage("Dados salvos com sucesso.");
          }
        })
        .catch((error) => {
          this.handleError(error.response);
        });

      this.loading = false;
    },

    async handleUpdate() {
      this.loading = true;

      const { id } = this.getFormData;

      const formData = {
        endpoints: this.getFormData.endpoints.map(
          (item) => item["id_endpoint"]
        ),
      };

      await updateDepartmentApiUser(id, formData)
        .then((response) => {
          if (response.status === 200) {
            this.clear();
            successMessage("Dados salvos com sucesso.");
          }
        })
        .catch((error) => {
          this.handleError(error.response);
        });

      this.loading = false;
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404;

      if (errors) {
        return warningMessage(response.data.error);
      }

      return warningMessage("Não foi possível realizar a sua requisição.");
    },

    setFormData() {
      return {
        idDepartamento: this.getFormData.department.id_departamento,
        departamentoNacional: this.getFormData.departmentType.boolValue,
        endpoints: this.getFormData.endpoints.map(
          (item) => item["id_endpoint"]
        ),
      };
    },

    cancel() {
      if (this.getMode === "insert") {
        this.redirect = true;
        this.clear();
      } else {
        this.$router.replace({
          name: "usuarios-integracao-list",
        });
      }
    },

    clear() {
      this.$emit("clear");
      this.$store.commit(
        "departamentoApiState/SET_CHOOSE_DEPARTMENT_API_USER",
        null
      );

      if (this.redirect) {
        this.$router.replace({
          name: "usuarios-integracao-list",
        });
      }
    },

    checkNationalDepartmentType() {
      this.getFormData.department = "";
      this.departmentsOptions = [];

      if (this.getFormData.departmentType != null) {
        let departmentTypeId = this.getFormData.departmentType.id;
        this.departmentsOptions = this.departments[departmentTypeId];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-card-form {
  padding: 3rem 2rem;
}

.select-success {
  color: #28c76f;
}

.select-danger {
  color: #ea5455;
}

.select-success:hover,
.select-danger:hover {
  text-decoration: underline;
}

@media (max-width: 400px) {
  .button-custom-size {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
