import axiosIns from '@/libs/axios';
import rotasAPI from '@/router/rotasAPI';

export const getAllDepartments = () => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.departamento())
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const getAllEndpoints = () => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.endpoint())
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const getAllUsersDepApi = params => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.departamentoApi(), { params })
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const getUserDepApi = id => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.departamentoApiId(id))
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const createDepartmentApiUser = formData => new Promise((resolve, reject) => {
    axiosIns
        .post(rotasAPI.departamentoApi(), formData)
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const handleDefineStatusUser = departmentApiId => new Promise((resolve, reject) => {
    axiosIns
        .put(rotasAPI.departamentoApiStatus(departmentApiId))
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const updateDepartmentApiUser = (id, formData) => new Promise((resolve, reject) => {
    axiosIns
        .put(rotasAPI.departamentoApiId(id), formData)
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const deleteDepartmentApiUser = id => new Promise((resolve, reject) => {
    axiosIns
        .delete(rotasAPI.departamentoApiId(id))
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})

export const getAllTypeDepartments = () => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.tipoDepartamento())
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(error)
        })
})